<script setup>
import { ref, nextTick, onMounted, watch } from 'vue';
import DropdownMenu from 'v-dropdown-menu';
import 'v-dropdown-menu/css';

import { Defaults } from '../../defaults';

const userStore = window.userStore;

const leftbar_status = parseInt(localStorage.getItem("leftbar_full") ?? 1);
const _BASE_LANG_URL = window._BASE_LANG_URL;
const _TRANSLATION = window._TRANSLATION;
const _LANG = window._LANG;
const _LANG_URLS = window._LANG_URLS;

const full = ref(leftbar_status);  // Is leftbar fullwidth?
const mobMenuToggled = ref(false);  // Is mob menu toggled?

const width = ref(window.innerWidth);

onMounted( () => {
    //Defaults.resize('.leftbar', 50);
    const resizeObserver = new ResizeObserver(entries => {
        if(window.innerWidth > 768){
            document.querySelector('.leftbar').style.height = `${entries[0].target.clientHeight + 50}px`;
        } else {
            document.querySelector('.leftbar').style.height = 'auto';
        }
    });
    resizeObserver.observe(document.body);
} );

onresize = (event) => {
    width.value = window.innerWidth;
    //Defaults.resize('.leftbar', 50);
};

/** Change leftbar state */
const changeState = () => {
    full.value = (full.value == 1) ? 0 : 1
    localStorage.setItem("leftbar_full", full.value);
}

watch(mobMenuToggled, (newVal, oldVal) => {
    nextTick(() => {
        if( width.value < 768 && newVal) {
            //document.querySelector('.leftbar').style.height = `${document.body.offsetHeight}px`;
            //document.querySelector('.leftbar').style.height = `100vh`;
            document.querySelector('.leftbar').style.height = 'auto';
            document.querySelector('.leftbar__content').style.minHeight = `${window.innerHeight - 70}px`;
            //document.querySelector('.leftbar__content').style.minHeight = 'calc(100vh - 70px)';

            document.querySelector('body').style.overflow = 'hidden';
        } else {
            document.querySelector('.leftbar').style.height = 'auto';
            document.querySelector('.leftbar__content').style.minHeight = 'calc(100vh - 106px)';
            document.querySelector('body').style.overflow = '';
        }

        /** Set selected lang */
        document.querySelector('.dropdown_langs-small .selected-lang__value').innerHTML = document.querySelector(`.dropdown_langs-small [data-lang="${_LANG}"]`).innerHTML;
    });
});

</script>

<template>
    <aside class="leftbar" :class="{ 'leftbar_min': full == 0 }">
        <div class="leftbar__head">
            <a :href="_BASE_LANG_URL" class="link-clear header-logo">
                <img src="../../../images/logo.png" alt="" class="logo-dashboard">
            </a>

            <div class="leftbar__management">
                <div class="burger" @click="mobMenuToggled = !mobMenuToggled">
                    <svg v-if="!mobMenuToggled" width="30" height="20" viewBox="0 0 30 20" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.875 1.875H28.125M1.875 10H28.125M1.875 18.125H28.125" stroke="white" stroke-width="3"
                            stroke-miterlimit="10" stroke-linecap="round" />
                    </svg>
                    <svg v-else width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M25.0673 25.2667L5.33398 5.53333M25.0673 5.53333L5.33398 25.2667" stroke="white"
                            stroke-width="3" stroke-linecap="round" />
                    </svg>
                </div>
            </div>
        </div>
        <transition>
            <div class="leftbar__content" v-if="(width < 768 && mobMenuToggled) || width >= 768">
                <button class="button-clear" :class="{ 'rotated': full == 0 }" id="leftbar-toggler"
                    @click="changeState"></button> <!-- Toggle leftbar -->
                <div class="leftbar__content-management">

                    <div class="top-nav-buttons only-before-tablets">
                        <button disabled class="button button_large button_flex" id="content"><img src="../../../images/book_green.png" alt=""
                                class="button__icon"><span>{{ _TRANSLATION.header.buttons.content }}</span>
                        </button>
                        <button disabled v-if="userStore.user != null" class="button button_large button_flex" id="subscriptions"><img
                                src="../../../images/sign.png" alt="" class="button__icon"><span>{{ _TRANSLATION.header.buttons.subscriptions }}</span>
                        </button>

                        <div v-else class="header__access-account header__access-account_quest">
                            <a :href="`${_BASE_LANG_URL}/auth/sign-up`" class="link-clear">
                                <button class="button button_large button_flex"
                                    id="head-register"><span>{{ _TRANSLATION.header.buttons.register }}</span></button>
                            </a>
                            <a :href="`${_BASE_LANG_URL}/auth/sign-in`" class="link-clear">
                                <button class="button button_large button_flex"
                                    id="head-auth"><span>{{ _TRANSLATION.header.buttons.auth }}</span></button>
                            </a>
                        </div>

                        <div class="border"></div>
                    </div>

                    <!-- Leftbar menu -->
                    <nav class="leftbar__menu">
                        <a :href="`${_BASE_LANG_URL}/crafts`" class="link-clear">
                            <button class="button button_large button_flex"><img src="../../../images/workbench.png" alt=""
                                class="button__icon"><span>{{ _TRANSLATION.leftbar.buttons.crafts }}</span></button>
                        </a>
                        <button disabled class="button button_large button_flex"><img src="../../../images/steve.png" alt=""
                                class="button__icon"><span>{{ _TRANSLATION.leftbar.buttons.your_crafts }}</span></button>
                        <a :href="`${_BASE_LANG_URL}/color-code`" class="link-clear"><button class="button button_large button_flex"><img src="../../../images/diamond.png" alt="" class="button__icon"><span>{{ _TRANSLATION.leftbar.buttons.color_codes }}</span></button>
                        </a>

                        <button disabled class="button button_large button_flex"><img src="../../../images/table.png" alt=""
                                class="button__icon"><span>{{ _TRANSLATION.leftbar.buttons.create_3d }}</span></button>
                        <button disabled class="button button_large button_flex"><img src="../../../images/apple.png"
                                alt="" class="button__icon"><span>{{ _TRANSLATION.leftbar.buttons.category_test
                                }}</span></button>
                    </nav>

                    <div class="leftbar__border"></div>

                    <!-- Your projects -->
                    <div class="your-projects">
                        <div class="your-projects__head">
                            <span v-if="userStore.user != null" class="your-projects__title active">{{
                                _TRANSLATION.leftbar.your_projects }}</span>
                            <span v-else class="your-projects__title">{{ _TRANSLATION.leftbar.your_projects_list }}</span>
                            <button class="button button_small button_flex"><span>+</span></button>
                        </div>

                        <div v-if="userStore.user != null" class="your-projects__list">
                            <div class="your-project">
                                <div class="your-project__order"><span>A</span></div>
                                <span class="your-project__name">MyHome1</span>
                            </div>
                            <div class="your-project active">
                                <div class="your-project__order"><span>B</span></div>
                                <span class="your-project__name active">> New01</span>
                            </div>
                            <div class="your-project">
                                <div class="your-project__order"><span>C</span></div>
                                <span class="your-project__name">Sample project 010101 craft 03</span>
                            </div>
                            <div class="your-project">
                                <div class="your-project__order"><span>D</span></div>
                                <span class="your-project__name">Sample 2</span>
                            </div>
                        </div>

                        <div v-else class="your-projects__empty">
                            <span class="your-projects__label">{{ _TRANSLATION.leftbar.no_projects }}</span>
                            <div class="account-links">
                                <a :href="`${_BASE_LANG_URL}/auth/sign-in`">{{ _TRANSLATION.leftbar.auth }}</a>
                                <a :href="`${_BASE_LANG_URL}/auth/sign-up`">{{ _TRANSLATION.leftbar.register }}</a>
                            </div>
                        </div>
                    </div>

                    <!-- Leftbar footer menu -->
                    <nav class="leftbar__menu leftbar__menu_bottom">
                        <!-- Contact us -->
                        <button disabled class="button button_large button_flex only-from-tablets"><img src="../../../images/chat.png" alt=""
                                class="button__icon"><span>{{ _TRANSLATION.leftbar.buttons.contact_us }}</span></button>

                        <div class="only-before-tablets text-center">
                            <p class="leftbar__menu-copyright">2023 AlaCraft - All rights reserved</p>

                            <dropdown-menu class="dropdown dropdown_langs dropdown_langs-small" :overlay="false" direction="left" :dropup="false">
                                <template #trigger>
                                    <div class="selected-lang">
                                        <div class="selected-lang__value">
                                            <span>UA</span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="19" viewBox="0 0 26 19" fill="none">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H26V19H0V0Z" fill="#FFD700" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H26V9.5H0V0Z" fill="#0057B8" />
                                            </svg>
                                        </div>
                                        <div class="dropdown-arrow">
                                        </div>
                                    </div>
                                </template>

                                <template #body>
                                    <ul>
                                        <li>
                                            <a :href="_LANG_URLS.en" class="link-clear" data-lang="en">
                                                <span>EN</span>
                                                <img src="../../../images/en.svg" alt="">
                                            </a>
                                        </li>
                                        <li>
                                            <a :href="_LANG_URLS.es" class="link-clear" data-lang="es">
                                                <span>ES</span>
                                                <img src="../../../images/es.svg" alt="">
                                            </a>
                                        </li>
                                        <li>
                                            <a :href="_LANG_URLS.de" class="link-clear" data-lang="de">
                                                <span>DE</span>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="19" viewBox="0 0 26 19" fill="none">
                                                    <g clip-path="url(#clip0_326_3581)">
                                                        <path d="M0 12.6666H26V19H0V12.6666Z" fill="#FFCE00" />
                                                        <path d="M0 0H26V6.33333H0V0Z" fill="black" />
                                                        <path d="M0 6.33337H26V12.6667H0V6.33337Z" fill="#DD0000" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_326_3581">
                                                            <rect width="26" height="19" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </a>
                                        </li>
                                        <li>
                                            <a :href="_LANG_URLS.fr" class="link-clear" data-lang="fr">
                                                <span>FR</span>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="20" viewBox="0 0 26 20" fill="none">
                                                    <g clip-path="url(#clip0_326_3587)">
                                                        <mask id="mask0_326_3587" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0"
                                                            y="0" width="26" height="20">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H26V20H0V0Z" fill="white" />
                                                        </mask>
                                                        <g mask="url(#mask0_326_3587)">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.875 0H26V20H17.875V0Z"
                                                                fill="#F50100" />
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H9.75V20H0V0Z"
                                                                fill="#2E42A5" />
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.125 0H17.875V20H8.125V0Z"
                                                                fill="#F7FCFF" />
                                                        </g>
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_326_3587">
                                                            <rect width="26" height="20" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </a>
                                        </li>
                                        <li>
                                            <a :href="_LANG_URLS.ua" class="link-clear" data-lang="ua">
                                                <span>UA</span>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="19" viewBox="0 0 26 19" fill="none">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H26V19H0V0Z" fill="#FFD700" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H26V9.5H0V0Z" fill="#0057B8" />
                                                </svg>
                                            </a>
                                        </li>
                                        <li>
                                            <a :href="_LANG_URLS.ru" class="link-clear" data-lang="ru">
                                                <span>RU</span>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="19" viewBox="0 0 26 19" fill="none">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H26V19H0V0Z" fill="white" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 6.33337H26V19H0V6.33337Z"
                                                        fill="#0039A6" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 12.6666H26V19H0V12.6666Z"
                                                        fill="#D52B1E" />
                                                </svg>
                                            </a>
                                        </li>
                                    </ul>
                                </template>

                            </dropdown-menu>

                            <ul class="ul-clear ul-mobile-nav">
                                <li>
                                    <a :href="`${_BASE_LANG_URL}/terms`">{{ _TRANSLATION.footer.terms }}</a>
                                </li>
                                <li>
                                    <a :href="`${_BASE_LANG_URL}/privacy`">{{ _TRANSLATION.footer.privacy }}</a>
                                </li>
                                <li>
                                    <a href="#">{{ _TRANSLATION.footer.support }}</a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </transition>
    </aside>
</template>

