<script setup>
import Leftbar from './Leftbar.vue';
import Header from './Header.vue';

import { Defaults } from '../../defaults';

const _TRANSLATION = window._TRANSLATION;
const _BASE_LANG_URL = window._BASE_LANG_URL;
const userStore = window.userStore;

const props = defineProps(['user']);
userStore.setUser(props.user);
</script>

<template>
    <div class="wrapper">
        <Leftbar></Leftbar>
        <main class="main">
            <Header></Header>

            <div class="main__content">
                <h1 class="h1-title">{{ _TRANSLATION.dashboard.title }}</h1>
                <div class="card card_title card_big-content">{{ _TRANSLATION.dashboard.subtitle }}</div>

                <div class="modules">
                    <!-- 1 -->
                    <div class="module" @click="Defaults.openLink(`${_BASE_LANG_URL}/color-code`)">
                        <div class="module__header">{{ _TRANSLATION.dashboard.modules.color_codes }}</div>
                        <div class="module__body">
                            <div class="module__body-content">
                                <img src="../../../images/module_codes.png" alt="" class="module__img"
                                    style="top: -58px;">
                            </div>
                        </div>
                    </div>
                    <!-- 2 -->
                    <div class="module"
                        @click="Defaults.openLink(userStore.user == null ? `${_BASE_LANG_URL}/auth/sign-up` : `${_BASE_LANG_URL}/profile`)">
                        <div class="module__header">{{ _TRANSLATION.dashboard.modules.profile }}</div>
                        <div class="module__body">
                            <div class="module__body-content">
                                <img src="../../../images/module_profile.png" alt="" class="module__img"
                                    style="top: -65px;">
                            </div>
                        </div>
                    </div>
                    <!-- 3 -->
                    <div class="module" @click="Defaults.openLink(`${_BASE_LANG_URL}/crafts`)">
                        <div class="module__header">{{ _TRANSLATION.dashboard.modules.crafts }}</div>
                        <div class="module__body">
                            <div class="module__body-content">
                                <img src="../../../images/module_crafts.png" alt="" class="module__img">
                            </div>
                        </div>
                    </div>
                    <!-- 4 -->
                    <div class="module module_disabled tooltip-box">
                        <div class="module__header">{{ _TRANSLATION.dashboard.modules.manual_crafts }}</div>
                        <div class="module__body">
                            <div class="module__body-content">
                                <img src="../../../images/module_manual_craft.png" alt="" class="module__img">
                                <div class="in-progress">
                                    <img src="../../../images/icon_gear.svg" alt="" class="in-progress__icon">
                                    <p>{{ _TRANSLATION.dashboard.in_progress}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="tooltip">
                            <span class="text">{{ _TRANSLATION.dashboard.tooltip }}</span>
                        </div>
                    </div>
                    <!-- 5 -->
                    <div class="module module_disabled tooltip-box">
                        <div class="module__header">{{ _TRANSLATION.dashboard.modules.fireworks }}</div>
                        <div class="module__body">
                            <div class="module__body-content">
                                <img src="../../../images/module_fireworks.png" alt="" class="module__img">
                                <div class="in-progress">
                                    <img src="../../../images/icon_gear.svg" alt="" class="in-progress__icon">
                                    <p>{{ _TRANSLATION.dashboard.in_progress }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="tooltip">
                            <span class="text">{{ _TRANSLATION.dashboard.tooltip }}</span>
                        </div>
                    </div>
                    <!-- 6 -->
                    <div class="module module_disabled tooltip-box">
                        <div class="module__header">{{ _TRANSLATION.dashboard.modules.flat_world }}</div>
                        <div class="module__body">
                            <div class="module__body-content">
                                <img src="../../../images/module_flat.png" alt="" class="module__img">
                                <div class="in-progress">
                                    <img src="../../../images/icon_gear.svg" alt="" class="in-progress__icon">
                                    <p>{{ _TRANSLATION.dashboard.in_progress}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="tooltip">
                            <span class="text">{{ _TRANSLATION.dashboard.tooltip }}</span>
                        </div>
                    </div>
                </div>
            </div>

        </main>
    </div>
</template>
