<script setup>
import { ref, reactive, onMounted, nextTick, watch } from 'vue';

import Leftbar from '../Leftbar.vue';
import Header from '../Header.vue';
import Banner from '../Banner.vue';

import DropdownMenu from 'v-dropdown-menu';
import 'v-dropdown-menu/css';

const props = defineProps(['user']);

window.userStore.setUser(props.user);

const year = new Date().getFullYear();  // Current year

const hasErrors = ref(false);  // Are errors set?
const fatalErrors = ref('');    // Fatal errors (From back)
const errors = reactive({});

const versions = ref(null);   // Versions
const crafts = ref({});   // Crafts

const version = ref(null);  // Current choosen version
const craft = ref(null);  // Current choosen craft
const search = ref(''); // Search phrase
const perPage = ref(50);    // Results per page

const _TRANSLATION = window._TRANSLATION;

const tooltipIndex = ref(-1);    // Tooltip index
const tooltipInlineStyles = ref({});    // Extra styles for tooltip

const contentShown = ref(false);   // Is content shown?

onMounted(async () => {
    await loadVersions();
    await loadCrafts(`${_BASE_LANG_URL}/crafts/load`);

    contentShown.value = true;
});

/** Load versions */
const loadVersions = async () => {
    return axios.post(`${_BASE_LANG_URL}/versions/load`, {})
        .then(function (response) {
            console.log(response);
            versions.value = response.data;
            version.value = response.data[0];
            console.log(versions.value);
            // Validation errors
            if (response.data.error != null) {
                hasErrors.value = true;
                response.data.error.forEach((value, index) => {
                    let errorType = Object.keys(value[0])[0];
                    errors[errorType] = value[0][errorType];
                });
                return;
            }

            // Logical errors
            if (response.data.fatal_error != null) {
                fatalErrors.value = response.data.fatal_error;
                return;
            }
        })
        .catch(function (error) {
            console.log(error);
            fatalErrors.value = error;
        });
}

/** Load crafts */
const loadCrafts = async (url) => {
    if (url == null) return;

    console.log(version.value);

    let data = {
        version: version.value.id,
        search: search.value,
        per_page: perPage.value
    };

    return axios.post(url, data)
        .then(function (response) {
            console.log(response);
            crafts.value = response.data;
            console.log(crafts.value);
            // Validation errors
            if (response.data.error != null) {
                hasErrors.value = true;
                response.data.error.forEach((value, index) => {
                    let errorType = Object.keys(value[0])[0];
                    errors[errorType] = value[0][errorType];
                });
                return;
            }

            // Logical errors
            if (response.data.fatal_error != null) {
                fatalErrors.value = response.data.fatal_error;
                return;
            }
        })
        .catch(function (error) {
            console.log(error);
            fatalErrors.value = error;
        });
}

/** Choose craft */
const choose = (index) => {
    craft.value = crafts.value.data[index];
    console.log(craft.value);
}

/** Choose version */
const chooseVersion = (versionId) => {
    version.value = versionId;
    console.log(version.value);

    nextTick(() => {
        document.querySelector('#dropdown-versions .dropdown-arrow').click();
    });

    loadCrafts(`${_BASE_LANG_URL}/crafts/load`);
}

/** Choose per page */
const choosePerPage = (value) => {
    perPage.value = value;

    nextTick(() => {
        document.querySelector('#dropdown-per-page .dropdown-arrow').click();
    });

    loadCrafts(`${_BASE_LANG_URL}/crafts/load`);
}

/** Toggle tooltip */
const toggleTooltip = (index, event) => {
    tooltipIndex.value = index;
    let target = event.target.closest('.craft-item__block');

    let pos = {
        top: target.offsetTop + (target.offsetHeight / 2),
        left: target.offsetLeft + (target.offsetWidth + 60)
    }

    console.log(pos);

    tooltipInlineStyles.value = {
        'top': `${pos.top}px`,
        'left': `${pos.left}px`,
    };
}

/** Reset tooltip */
const resetTooltip = () => {
    tooltipIndex.value = -1;
    //tooltipInlineStyles.value = {};
}

// Search
watch(search, (newVal, oldVal) => {
    if( newVal.length < 2 && newVal.length > 0 ) return;

    loadCrafts(`${_BASE_LANG_URL}/crafts/load`);
});

</script>

<template>
    <div class="wrapper">
        <Leftbar></Leftbar>
        <main class="main">
            <Header></Header>
            <!-- Main content -->
            <transition>
                <div class="main__content" v-if="contentShown">
                    <Banner :title="_TRANSLATION.crafts.title" :extra_class="'banner_tall'"></Banner>

                    <div class="card no-padding crafts">
                        <div class="card__content crafts__card-description">
                            <p>{{ _TRANSLATION.crafts.description }}</p>
                            <p><span style="color: rgba(255, 255, 255, 0.6);">{{ _TRANSLATION.crafts.subtitle }}</span> 1.21 / 1.20.6 / 1.19.4 / 1.18.2 / 1.17.1 / 1.16.5 / 1.15.2 / 1.14.4 / 1.12.2 / 1.7.10</p>
                        </div>
                    </div>

                    <div class="card no-padding crafts">
                        <div class="card__content crafts__card-description">
                            <div class="crafts__filters">
                                <input type="text" class="input input_smarter" name="nickname" id="nickname"
                                    :placeholder="_TRANSLATION.crafts.input_search" v-model="search">
                                <div>
                                    <span style="margin-right: 4px;">{{ _TRANSLATION.crafts.filters.for_minecraft }}</span>
                                    <dropdown-menu class="dropdown dropdown_lite" id="dropdown-versions"
                                        :overlay="false" :direction="left" :dropdown="true">
                                        <template #trigger>
                                            <div class="selected-flex">
                                                <div class="selected-value">
                                                    <span>{{ version != null ? version.name : '...' }}</span>
                                                </div>
                                                <div class="dropdown-arrow"></div>
                                            </div>
                                        </template>

                                        <template #body>
                                            <ul>
                                                <li v-for="(value, index) in versions" @click="chooseVersion(value)">
                                                    <span>{{ value.name }}</span>
                                                </li>
                                            </ul>
                                        </template>

                                    </dropdown-menu>
                                </div>

                                <div class="crafts__per-page">
                                    <span style="margin-right: 10px;">{{ _TRANSLATION.crafts.filters.per_page }}</span>
                                    <dropdown-menu class="dropdown dropdown_lite" id="dropdown-per-page"
                                        :overlay="false" :direction="left" :dropdown="true">
                                        <template #trigger>
                                            <div class="selected-flex">
                                                <div class="selected-value">
                                                    <span>{{ perPage }}</span>
                                                </div>
                                                <div class="dropdown-arrow"></div>
                                            </div>
                                        </template>

                                        <template #body>
                                            <ul>
                                                <li @click="choosePerPage(10)">10</li>
                                                <li @click="choosePerPage(20)">20</li>
                                                <li @click="choosePerPage(50)">50</li>
                                                <li @click="choosePerPage(100)">100</li>
                                            </ul>
                                        </template>

                                    </dropdown-menu>
                                </div>
                            </div>
                        </div>


                        <div class="card__content crafts__content">
                            <transition mode="out-in">
                                <span v-if="craft == null" class="craft-empty">{{ _TRANSLATION.crafts.choose_craft }}</span>
                                <div v-else class="craft-choosen">
                                    <div class="craft-choosen__ingradients">
                                        <span>{{ _TRANSLATION.crafts.ingradients }}</span>
                                        <transition-group name="list" mode="in-out">
                                            <template v-for="(value, index) in craft.items" :key="index">
                                                <div class="craft-choosen__ingradient" v-if="value != null">
                                                    <img :src="value.block.image_url"
                                                        :alt="value.block.default_lang_name" class="img-adaptive">
                                                    <span>{{ value.block.default_lang_name }}</span>
                                                    <span class="amount">x{{ value.amount }}</span>
                                                </div>
                                            </template>
                                        </transition-group>
                                    </div>

                                    <div class="craft-choosen__template">
                                        <span>{{ _TRANSLATION.crafts.crafting }}</span>
                                        <div class="craft-workbench">
                                            <div class="craft-workbench__grid">
                                                <div class="cell" v-for="(value, index) in 9" :key="index">
                                                    <img class="img-adaptive" :src="craft.items[index].block.image_url"
                                                        :alt="craft.items[index].block.default_lang_name"
                                                        v-if="craft.items[index] != null && craft.items[index].block.image_url != null"
                                                        :data-pos="index">
                                                </div>
                                            </div>

                                            <img class="craft-workbench__arrow" alt="Arrow"
                                                src="../../../../images/icon-craft_result.png">

                                            <div class="craft-workbench__grid craft-workbench__grid_result">
                                                <div class="cell cell_result">
                                                    <img class="img-adaptive" :src="craft.block.image_url"
                                                        :alt="craft.block_default_name">

                                                    <div class="clear-craft" @click="craft = null">
                                                        <img src="../../../../images/icon_clear.svg" alt="Clear Craft"
                                                            class="img-adaptive">
                                                    </div>

                                                    <div class="cell_result__amount">{{ craft.result_amount }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="craft-choosen__item-big">
                                        <img :src="craft.block.image_url" :alt="craft.block.default_lang_name"
                                            class="img-adaptive">
                                        <span>{{ craft.block.default_lang_name }}</span>
                                    </div>
                                </div>
                            </transition>
                        </div>

                        <transition mode="out-in">
                            <div class="card__content crafts__list-wrapper"
                                v-if="crafts.data && crafts.data.length > 0">
                                <div class="crafts__list">
                                    <transition-group mode="out-in">
                                        <div class="craft-item" :key="index" v-for="(value, index) in crafts.data">
                                            <div @click="choose(index)" class="craft-item__block"
                                                :class="{ 'active': craft != null && craft.id == value.id }"
                                                @mouseover="toggleTooltip(index, $event)" @mouseleave="resetTooltip()">
                                                <img :src="value.block.image_url" :alt="value.default_lang_name">
                                                <div class="tooltip tooltip_craft tooltip_zero_transition"
                                                    :style="[{ 'opacity': tooltipIndex == index ? 1 : 0 }, tooltipInlineStyles]">
                                                    <span>{{ value.default_lang_name }}</span>
                                                </div>
                                            </div>
                                            <span class="craft-item__name">{{ value.default_lang_name }}</span>
                                        </div>
                                    </transition-group>
                                </div>

                                <div class="crafts__pagination" v-if="crafts.last_page > 1">
                                    <button @click="loadCrafts(value.url)" class="page"
                                        :class="{ 'page_active': value.active }" v-for="(value, index) in crafts.links">
                                        <img v-if="value.label.includes('laquo')"
                                            src="../../../../images/leftbar-arrow_left.svg" alt="" class="img-adaptive">
                                        <img v-else-if="value.label.includes('raquo')"
                                            src="../../../../images/arrow_right.svg" alt=""
                                            class="img-adaptive arrow-right">
                                        <span v-else>{{ value.label }}</span>
                                    </button>
                                </div>
                            </div>

                            <p class="crafts-empty" v-else>
                                {{ _TRANSLATION.crafts.search_empty }}
                            </p>
                        </transition>
                    </div>

                    <p class="craft-copyright" v-html="_TRANSLATION.crafts.copyright.replace(':YEAR', year)"></p>
                </div>
            </transition>
        </main>
    </div>
</template>
