<script setup>
import { ref, reactive } from 'vue';
import Footer from '../parts/Footer.vue';
import validation from '../../validation';

const hasErrors = ref(false);  // Are errors set?
const errors = reactive({});   // List of errors
const fatalErrors = ref('');    // Fatal errors (From back)
const submitButtonDisabled = ref(false); // Is submit button disabled?

const emailSent = ref(false);   // Was email sent?

const _TRANSLATION = window._TRANSLATION;
const _BASE_LANG_URL = window._BASE_LANG_URL;

// Validate
const validate = (callback, type) => {
    let result = callback;
    if(!result.valid) {
        hasErrors.value = true;
        errors[type] = result.error;
    }
};

// Reset Pass
const reset = () => {
    submitButtonDisabled.value = true;

    emailSent.value = false;
    fatalErrors.value = '';

    if( Object.keys(errors).length == 0 ) hasErrors.value = false;
    let email = document.getElementById('email').value;
    let emailTest = validation.isEmailValid(email);
    if( !emailTest.valid ) {
        hasErrors.value = true;
        errors.email = emailTest.error;
    }

    if(hasErrors.value) {
        submitButtonDisabled.value = false;
        return; // If errors are present, stop here
    }

    // Reset Pass Action
    axios.post('/reset_pass', { email })
    .then(function (response) {
        console.log(response);
        // Validation errors
        if(response.data.error != null){
            hasErrors.value = true;
            response.data.error.forEach( (value, index)  => {
                let errorType = Object.keys(value[0])[0];
                errors[errorType] = value[0][errorType];
            });
            submitButtonDisabled.value = false;
            return;
        }

        // Logical errors
        if(response.data.fatal_error != null){
            fatalErrors.value = response.data.fatal_error;
            submitButtonDisabled.value = false;
            return;
        }

        emailSent.value = true;
    })
    .catch(function (error) {
        console.log(error);
        fatalErrors.value = error;
        submitButtonDisabled.value = false;
    });
}
</script>

<template>
    <transition appear>
        <div class="wrapper-small">
            <a :href="_BASE_LANG_URL" class="link-clear link-logo">
                <img src="../../../images/logo.png" class="img-adaptive logo" alt="Logo">
            </a>

            <div class="box">
                <h1 class="title">{{ _TRANSLATION.pass_reset.title }}</h1>

                <form class="form" id="pass_reset-form" @submit.prevent="reset">
                    <p class="form__label">{{ _TRANSLATION.pass_reset.email_label }}</p>
                    <div class="input-group">
                        <label for="email">{{ _TRANSLATION.common.email }}</label>
                        <input type="email" class="input" :class="{'input_error': hasErrors && errors.email, 'input_success': emailSent}" name="email" id="email" :placeholder="_TRANSLATION.common.email" @input="delete errors.email" :onFocusout="(event) => validate( validation.isEmailValid(event.target.value), 'email' )" :style="[emailSent ? {color: '#52FF00'} : {}]">
                        <transition>
                                <div class="input-group__error" v-if="hasErrors && errors.email">{{ errors.email }}</div>
                        </transition>
                    </div>

                    <transition>
                        <div class="success-message" v-if="emailSent">
                            {{ _TRANSLATION.pass_reset.instruction_sent }}
                        </div>
                    </transition>

                    <transition>
                        <p class="fatal-errors" v-if="fatalErrors.length > 0">
                            {{  fatalErrors  }}
                        </p>
                    </transition>

                    <button class="btn" type="button" id="resetPass" :disabled="submitButtonDisabled" @click="reset"><span>{{ _TRANSLATION.pass_reset.send }}</span></button>

                    <div class="text-center form__links">
                        <a :href="`${_BASE_LANG_URL}/auth/sign-up`">{{ _TRANSLATION.pass_reset.register }}</a>
                        <a :href="_BASE_LANG_URL+ '/auth/sign-in'">{{ _TRANSLATION.pass_reset.auth }}</a>
                    </div>
                </form>
            </div>

            <!-- Footer Component -->
            <Footer></Footer>
        </div>
    </transition>
</template>
